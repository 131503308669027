@import "../../_globalColor";

.construction-text {
  text-align: center;
  color: $subTitle !important;
  font-size: 2rem;
}

.dark-mode {
  color: $textColorDark !important;
}

.construction-div {
  margin-top: 2rem;
  padding-bottom: 2rem;
}
