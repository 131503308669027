@import "../../_globalColor";

.footer-text {
  text-align: center;
  color: $subTitle !important;
  font-size: 0.6rem;
}

.dark-mode {
  color: $textColorDark !important;
}

.footer-div {
  margin-top: 1rem;
  padding-bottom: 0.5rem;
}
